import React from 'react';

import SiteLayout from '@layouts/site-layout';

export default class InfoPage extends React.Component {
    render() {
        return (
            <SiteLayout>
                <h3 className="has-text-weight-bold is-size-1">Info page</h3>
            </SiteLayout>
        )
    }
}
